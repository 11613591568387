import './Header.css';

export default function Header() {
    return (
        <header>
            <div className="logo">ForkVenture</div>
            <nav>
                <a href="#features">Features</a>
                <a href="#solutions">Solutions</a>
                <a href="#contact">Contact</a>
            </nav>
            <button className="cta-btn">Try Now</button>
        </header>
);
}