import './Hero.css';
import logo from '../../assets/forkventure_logo.webp';

export default function Hero() {
    return (
        <section className="hero">
            <img src={logo} alt="logo" />
            <h1>Transforming the Future with AI-Powered Solutions</h1>
            <p>Humans and AI agents building startups together</p>
            <button className="cta-btn">Explore Features</button>
        </section>
    );
}