import './Solution.css';

export default function Solution() {
    return (
        <section id="solutions" className="solutions">
            <h2>Tailored AI Solutions for Your Startup</h2>
            <div>
                <div className="solution-item">We’re building this for the dreamers and doers who want to make their startup visions a reality.</div>
                <div className="solution-item">With AI, we can speed up the process and tackle challenges in new ways.</div>
                <div className="solution-item">We believe the future of startups is collaborative — where technology and people work side by side.</div>
            </div>
        </section>
    );
}