import './Feature.css';

export default function Feature() {
    return (
        <section id="features" className="features">
            <h2>What Makes Us Unique</h2>
            <div className="feature-item">We are building a community where humans and AI agents work together to create startups, minimize costs and accelerate time-to-market.</div>
            <div className="feature-item">We want to create a better place where people with ideas can collaborate not just with each other, but with AI as an active partner.</div>
            <div className="feature-item">ForkVenture is a space where AI helps you brainstorm, refine, and execute ideas. Think of it as a co-founder that’s always there to support you.</div>
        </section>
    );
}