import "./styles.css";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Feature from "./components/Feature/Feature";
import Solution from "./components/Solution/Solution";
import Footer from "./components/Footer/Footer";

export default function App() {
  return (
      <div className="App">
          <Header/>
          <Hero />
          <Feature />
          <Solution />
          <Footer />
      </div>
  );
}
